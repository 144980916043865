$background-medium: #822c24;
$background-dark: #65241d;
$background-darker: #400907;
$background-darkest: #2E2229;

$carousel-item-height: 200px;

@mixin zero-margin($pad-up-dn, $pad-left-right) {
  margin:0px auto;
  padding: $pad-up-dn $pad-left-right;
}

.jumbotron {
  @include zero-margin(70px,30px);
  background-color: $background-medium !important;
  color: floralwhite;
  .img-fluid {
    margin: 10px 10px 10px 10px;
    max-width: 256px;
  }
  .media-body {
    margin: 0px 10px 10px 0px;
    max-width: 250px;
    font-size: large;
  }
}

.top-margin {
  margin: 20px 0px 0px 0px;
  color: floralwhite;
}

.top-margin-body {
  margin: 50px 0px 0px 0px;
  color: floralwhite;
}

.white-anchor {
  margin: 0px 10px 10px 0px;
  color: #eee;
  a:link {
    color: #eee;
  }
  a:visited {
    color: #eee;
  }
}

.tooltip-inner {
  a a:visited {
    color: #eee;
  }
}

.row-header {
  @include zero-margin(0px,0px);
}

.row-content {
  @include zero-margin(50px,0px);
  min-height:300px;
}

.col-header {
  margin: 10px 10px 10px 10px;
}

body {
  padding: 0px 0px 0px 0px;
  z-index: 0;
  background-color: $background-darkest !important;
}

.navbar-dark {
  background-color: $background-dark;
}

.navbar-toggler {
  margin: 0px 0px 0px 20px;
}

.nav-item {
  margin: 0px 0px 0px 20px;
}

.maincarousel {
  background: $background-darkest;

  .maincarousel-item {
    height: $carousel-item-height;
    img {
      position: absolute;
      top: 0;
      left: 0;
      min-height: $carousel-item-height;
    }
  }

  .carousel-anchor {
    color: #bbb;
  }

  .carousel-anchor:hover {
    color: #eee;
  }
}

#carouselButton {
  right:0px;
  position: absolute;
  bottom: 0px;
  z-index: 1;
}

.page-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}
.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}
.page-exit {
    opacity: 0.01;
    transform: translateX(100%);
}
.page-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 1ms;
}

.card {
    margin: 0px 0px 50px 0px;
}

.card-body {
  color: floralwhite;
  background-color: $background-darkest;
  a {
    color: #eee !important;
  }
}

.black-text {
  color: #000 !important;
}

.card-quote {
  padding: 10px 10px 30px 10px;
}

.blockquote-footer {
  color: #eee !important;
}

.footer {
  color: #eee;
  background-color: $background-darker;
  @include zero-margin(20px, 0px);

  a:link {
    color: #eee;
  }

  a:visited {
    color: #eee;
  }

}